import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AppRoutes } from "./AppRouter";

function App() {
  useEffect(() => {
    document.title = "EmilGram.DK";
  }, []);

  return (
    <BrowserRouter basename="/">
      <Routes>
        {AppRoutes.map((route) => (
          <Route key={route.name} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
