import Home from "./pages/Home";

export const AppRoutes = [
  {
    name: "Home",
    path: "/",
    index: true,
    element: <Home />,
  },
];
